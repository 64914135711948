import React from 'react';
import { ThemeProvider } from 'styled-components';
import { CoverAuthorProps } from './author.types';

import { CoverContainer } from '../container';
import { Layout } from '../../../theme/layout';
import { getTheme, Theme } from '../../../theme/color';
import { Media, MediaContainer, ContentContainer, Name, Text, Placeholder } from './author.styles';
import { plainTextToHTML } from '../../../common/text';
import { SocialLinks } from '../../core/socialLinks';


export const CoverAuthor = ({name, text, image, socialLinks}:CoverAuthorProps) => {

  return (
    <ThemeProvider theme={getTheme(Theme.OFF_WHITE)}>
      <CoverContainer layout={Layout.GRID}>
        <MediaContainer>
          {image && image.gatsbyImageData ? 
            <Media defaultSource={image} />
          :
            <Placeholder viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M109.5 219C169.975 219 219 169.975 219 109.5C219 49.0248 169.975 0 109.5 0C49.0248 0 0 49.0248 0 109.5C0 169.975 49.0248 219 109.5 219Z" fill="#DBDBDB"/>
              <path d="M109.5 132.436C77.4628 132.436 47.7232 142.255 23.1191 159.048C40.3018 188.938 72.5512 209.067 109.5 209.067C146.448 209.067 178.698 188.938 195.881 159.048C171.276 142.255 141.533 132.436 109.5 132.436Z" fill="white"/>
              <path d="M109.502 115.242C135.54 115.242 156.648 94.1336 156.648 68.0955C156.648 42.0573 135.54 20.9492 109.502 20.9492C83.4636 20.9492 62.3555 42.0573 62.3555 68.0955C62.3555 94.1336 83.4636 115.242 109.502 115.242Z" fill="white"/>
            </Placeholder>
          } 
        </MediaContainer>
        <ContentContainer>
          <Name>{name}</Name>
          {text && <Text>{plainTextToHTML(text)}</Text>}
          {socialLinks && socialLinks.length > 0 && <SocialLinks links={socialLinks} />}
        </ContentContainer>
      </CoverContainer>
    </ThemeProvider>
  )
};