import styled from 'styled-components';
import { ThemeType } from '../../../theme/color';
import { getSpacerAsPixels, getUnitAsPixels } from '../../../theme/layout';

export const Container = styled.ul`
  display: flex;
  margin: ${getUnitAsPixels({multiplier:-1.5})};
  margin-bottom: ${getSpacerAsPixels({multiplier:1})};

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Item = styled.li`
  
`;

export const Link = styled.a<{theme:ThemeType}>`
  padding: ${getUnitAsPixels({multiplier:1.5})};
  display: block;
  transition: opacity 0.3s;

  >svg{
    fill: ${({theme}) => theme.secondary.hex};
  }

  &:hover{
    opacity: 0.7;
  }
`;