import styled from "styled-components"
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"
import { ResponsiveMedia } from "../../core/responsiveMedia"


export const MediaContainer = styled.div`
  grid-column: 1 / span 2;
`

export const ContentContainer = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 4 / span 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 6;
  }
`

export const Media = styled(ResponsiveMedia)`
  border-radius: 50%;
  overflow: hidden;
`

export const Placeholder = styled.svg`
  width: 100%;
  display: block;
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: 0;
  }
`

export const Name = styled.h1`
  ${getTypography(Type.MEDIUM_HEADING)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }

  >p{
    margin-bottom: ${getSpacerAsPixels()};

    &:last-child{
      margin-bottom: 0;
    }
  }
  
`;