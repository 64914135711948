import React from 'react';
import { useTheme } from 'styled-components';
import { useDictionary } from '../../../../context/dictionary';
import { Layout } from '../../../../theme/layout';
import { ArticleList } from '../../../article/articleList';
import { ArticleListVariant } from '../../../article/articleList/articleList.types';
import { BlockContainer } from '../../../block/container';
import { BlockTextAndMediaInternal } from '../../../block/textAndMediaInternal';
import { BlockTextAndMediaInternalVariant } from '../../../block/textAndMediaInternal/textAndMediaInternal.types';
import { Container } from './author.styles';
import { BodyAuthorProps } from './author.types';

export const BodyAuthor = ({articles,heading,text,image,socialLinks}:BodyAuthorProps) => {
  
  const theme = useTheme();

  const labelBlogArticles = useDictionary("labelBlogArticles");
  const labelBlogArticlesCount = useDictionary("labelBlogArticlesCount");
  const subheading = `${articles.length} ${labelBlogArticlesCount}`
  
  return (
    <BlockContainer layout={Layout.BLOCK} extraSpaceAfter={true} extraSpaceBefore={true}>
      <ArticleList variant={ArticleListVariant.DEFAULT} items={articles} heading={labelBlogArticles} subheading={subheading} />
    </BlockContainer>
  )
};