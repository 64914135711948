import { GatsbyImageProps } from 'gatsby-plugin-image';

export interface DatoModel {
  apiKey: string;
}

export interface BlogTopic {
  name: string;
  slug: string;
}

export interface BlogAuthor {
  name: string;
  slug: string;
}

export interface ArticleMeta {
  firstPublishedAtFormatted: string;
}

export interface ThemeColor {
  hex: string;
}

export interface Theme {
  name: string;
  background: ThemeColor;
  primary: ThemeColor;
  secondary: ThemeColor;
}

export interface MediaInternal {
  id: string;
  gatsbyImageData?: GatsbyImageProps;
  video?: {
    streamingUrl: string;
    mp4Url: string;
    thumbnailUrl: string;
  };
  width: number;
  height: number;
  alt: string;
  title: string;
}

export interface MediaExternal {
  id: string;
  url: string;
  provider: string;
  width: number;
  height: number;
  alt: string;
}

export interface LinkInternal {
  model?: DatoModel;
  label: string;
  page: {
    model: DatoModel;
    locale: string;
    slug?: string;
    section?: {
      id: string;
    };
  };
}

export interface LinkExternal {
  model?: DatoModel;
  label: string;
  url: string;
}

export enum SocialPlatform {
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  YOUTUBE = 'YouTube',
  TWITTER = 'Twitter',
}

export interface LinkSocial {
  model?: DatoModel;
  platform: SocialPlatform;
  url: string;
}

export interface OverlayMediaInternal {
  model: DatoModel;
  media: MediaInternal;
}

export interface OverlayMediaExternal {
  model: DatoModel;
  media: MediaExternal;
}
