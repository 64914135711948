import React from 'react';
import { Container, Item, Link } from './socialLinks.styles';
import { SocialIconProps, SocialLinksProps } from './socialLinks.types';

import FacebookIcon from '../../../images/facebook.svg';
import InstagramIcon from '../../../images/instagram.svg';
import YouTubeIcon from '../../../images/youtube.svg';
import TwitterIcon from '../../../images/twitter.svg';
import { SocialPlatform } from '../../../common/types';

const Icon = ({platform}:SocialIconProps) => {
  switch(platform){
    case SocialPlatform.FACEBOOK:
      return <FacebookIcon />
    case SocialPlatform.INSTAGRAM:
      return <InstagramIcon />
    case SocialPlatform.YOUTUBE:
      return <YouTubeIcon />
    case SocialPlatform.TWITTER:
      return <TwitterIcon />
  }
}

export const SocialLinks = ({links}:SocialLinksProps) => {
  return (
    <Container>
      {links?.map((link,index) => (
        <Item key={index}>
          <Link href={link.url} target="_blank"><Icon platform={link.platform} /></Link>
        </Item>
      ))}
    </Container>
  )
}