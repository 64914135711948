export enum Color {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  LIGHT_GREY = 'LIGHT_GREY',
  MID_GREY = 'MID_GREY',
  TRANSPARENT = 'TRANSPARENT',
  YELLOW = 'YELLOW',
  BLUE = 'BLUE',
  OFF_WHITE = 'OFF_WHITE',
}

export enum Theme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  OFF_WHITE = 'OFF_WHITE',
}

export type ThemeType = {
  background: {
    hex: string;
  };
  primary: {
    hex: string;
  };
  secondary: {
    hex: string;
  };
};

const colors: { [key in Color]: string } = {
  [Color.WHITE]: '#ffffff',
  [Color.BLACK]: '#000000',
  [Color.LIGHT_GREY]: '#d3d3d3',
  [Color.MID_GREY]: '#9DA8A9',
  [Color.TRANSPARENT]: 'transparent',
  [Color.YELLOW]: '#FCBE00',
  [Color.BLUE]: '#084198',
  [Color.OFF_WHITE]: '#F6F6F6',
};

export const getColor = (color: Color): string => colors[color];

const themes: { [key in Theme]: ThemeType } = {
  [Theme.LIGHT]: {
    background: {
      hex: getColor(Color.WHITE),
    },
    primary: {
      hex: getColor(Color.BLACK),
    },
    secondary: {
      hex: getColor(Color.BLUE),
    },
  },
  [Theme.DARK]: {
    background: {
      hex: getColor(Color.BLACK),
    },
    primary: {
      hex: getColor(Color.WHITE),
    },
    secondary: {
      hex: getColor(Color.YELLOW),
    },
  },
  [Theme.OFF_WHITE]: {
    background: {
      hex: getColor(Color.OFF_WHITE),
    },
    primary: {
      hex: getColor(Color.BLACK),
    },
    secondary: {
      hex: getColor(Color.BLUE),
    },
  },
};

export const getTheme = (theme: Theme): ThemeType => themes[theme];

export const getThemeByName = (name: string): ThemeType => {
  if (name) {
    switch (name.toUpperCase()) {
      case 'DARK':
        return themes[Theme.DARK];
      case 'LIGHT':
        return themes[Theme.LIGHT];
    }
  }

  return themes[Theme.LIGHT];
};
