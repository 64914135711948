import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { BodyEditorial } from '../components/layout/body/editorial';
import { ArticleList } from '../components/article/articleList';
import { BodyAuthor } from '../components/layout/body/author';
import { CoverAuthor } from '../components/cover/author';

export const query = graphql`
  query BlogAuthorQuery($id: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsBlogAuthor(id: { eq: $id }) {
      model {
        ...PageModel
      }
      locale
      name
      text
      image {
        ...MediaInternal
      }
      socialLinks {
        ...LinkSocial
      }
    }
    articles: allDatoCmsBlogArticle(
      filter: {slug: {ne: null}, authors: {elemMatch: {id: {eq: $id}}}}
    ) {
      nodes {
        model {
          ...PageModel
        }
        meta {
          ...PageMeta
        }
        slug
        title
        thumbnail{
          ...SquareThumbnail
        }
        authors {
          ...Author
        }
        topics {
          ...Topic
        }
      }
    }
  }
`;

const BlogAuthor = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const site = prop('site', data);
  const articles:any = path(['articles', 'nodes'], data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <CoverAuthor name={page.name} text={page.text} image={page.image} socialLinks={page.socialLinks} />
      <BodyAuthor articles={articles} heading={page.name} text={page.text} image={page.image} socialLinks={page.socialLinks} />
    </Fragment>
  );
};

export default BlogAuthor;